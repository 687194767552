import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Navigation } from "swiper";

export default class HeroOne extends React.Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>
				<section className="main-slider">
					<Swiper
						loop={true}
						slidesPerView={1}
						effect="fade"
						autoplay={{ delay: 5000 }}
						modules={[EffectFade, Pagination, Navigation]}
						pagination={{
							el: "#main-slider-pagination",
							type: "bullets",
							clickable: true
						}}
						navigation={{
							nextEl: "#main-slider__swiper-button-next",
							prevEl: "#main-slider__swiper-button-prev"
						}}
						className="swiper-container thm-swiper__slider">

						<div className="swiper-wrapper">


							<SwiperSlide className="swiper-slide">
								<div className="image-layer" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/slide1.jpg)' }}></div>
								<div className="main-slider-shape-1"></div>
								<div className="main-slider-shape-2"></div>
								<div className="main-slider-shape-3 zoominout"></div>
								<div className="main-slider-shape-4 zoominout-2"></div>
								<div className="main-slider-shape-5">
									<img src={publicUrl + "assets/images/shapes/main-slider-shape-5.png"} alt="" />
								</div>
								<div className="container">
									<div className="row">
										<div className="col-xl-7">
											<div className="main-slider__content">
												<h2>Give A <span>Little</span>  Change  <br />  A Lot</h2>
												<p>ARDO is currently handling about 300 people of all ages, out of which most of them belong to<br /> families which are already fighting poverty.! </p>
												<Link to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">Read More</Link>
												<div className="main-slider-arrow">
													<img src={publicUrl + "assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>

							<SwiperSlide className="swiper-slide">
								<div className="image-layer" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/slide2.jpg)' }}></div>
								<div className="main-slider-shape-1"></div>
								<div className="main-slider-shape-2"></div>
								<div className="main-slider-shape-3 zoominout"></div>
								<div className="main-slider-shape-4 zoominout-2"></div>
								<div className="main-slider-shape-5">
									<img src={publicUrl + "assets/images/shapes/main-slider-shape-5.png"} alt="" />
								</div>
								<div className="container">
									<div className="row">
										<div className="col-xl-7">
											<div className="main-slider__content">
												<h2>More <span> Help </span> More<br /> Better Life</h2>
												<p>ARDO took a first step in order to ensure a happy and a peaceful end for their lives!also<br /> works to ensure them a happy life. </p>
												<Link to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">Read More</Link>
												<div className="main-slider-arrow">
													<img src={publicUrl + "assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>

							<SwiperSlide className="swiper-slide">
								<div className="image-layer" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/slide3.jpg)' }}></div>
								<div className="main-slider-shape-1"></div>
								<div className="main-slider-shape-2"></div>
								<div className="main-slider-shape-3 zoominout"></div>
								<div className="main-slider-shape-4 zoominout-2"></div>
								<div className="main-slider-shape-5">
									<img src={publicUrl + "assets/images/shapes/main-slider-shape-5.png"} alt="" />
								</div>
								<div className="container">
									<div className="row">
										<div className="col-xl-7">
											<div className="main-slider__content">
												<h2>Raise <span>Fund</span> Warm <br /> Heart</h2>
												<p>ARDO has been set up with an aim to provide a better life for those suffering as their life time <br />  on earth is very meager. </p>
												<Link to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">Read More</Link>
												<div className="main-slider-arrow">
													<img src={publicUrl + "assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>

							<SwiperSlide className="swiper-slide">
								<div className="image-layer" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/backgrounds/slide4.jpg)' }}></div>
								<div className="main-slider-shape-1"></div>
								<div className="main-slider-shape-2"></div>
								<div className="main-slider-shape-3 zoominout"></div>
								<div className="main-slider-shape-4 zoominout-2"></div>
								<div className="main-slider-shape-5">
									<img src={publicUrl + "assets/images/shapes/main-slider-shape-5.png"} alt="" />
								</div>
								<div className="container">
									<div className="row">
										<div className="col-xl-7">
											<div className="main-slider__content">
												<h2>Raise <span>Your</span> Funds <br /> For A Cause</h2>
												<p>ARDO collaborating with government and private organisations in drug development and <br /> other services such as development of rehabilitation center,diagnosis. </p>
												<Link to={process.env.PUBLIC_URL + `/about`} className="thm-btn main-slider__btn">Read More</Link>
												<div className="main-slider-arrow">
													<img src={publicUrl + "assets/images/shapes/main-slider-shape-1.png"} className="float-bob-x" alt="" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>


						</div>

						<div className="swiper-pagination" id="main-slider-pagination"></div>

						<div className="main-slider__nav">
							<div className="swiper-button-prev" id="main-slider__swiper-button-next">
								<i className="fa fa-long-arrow-alt-left"></i>
							</div>
							<div className="swiper-button-next" id="main-slider__swiper-button-prev">
								<i className="fa fa-long-arrow-alt-right"></i>
							</div>
						</div>

					</Swiper>
				</section>
			</>
		)
	}
}