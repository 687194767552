import React from 'react';

export default class TeamOne extends React.Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>
				<section className="team-one">
					<div className="container">
						<div className="section-title text-center">
							<span className="section-title__tagline">Expert Team</span>
							<h2 className="section-title__title">Meet Our ARDO Team.</h2>
						</div>
						<div className="row">
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInLeft" data-wow-delay="100ms">

								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-1.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> Sobharani Sunkara </h4>
										<p className="team-one__title">Executive Dirextor & Founder</p>
									   
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">
							<div className="team-one__single">
								<div className="team-one__img">
									<img src={publicUrl + "assets/images/team/team-1-2.jpg"} alt="" />
								</div>
								<div className="team-one__content">
									<h4 className="team-one__name"> DR. Jagadeesh Babu Rangisetti </h4>
									<p className="team-one__title">Founder & CEO Biophore</p>
									
								</div>
							</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">

							<div className="team-one__single">
								<div className="team-one__img">
									<img src={publicUrl + "assets/images/team/team-1-13.jpg"} alt="" />
								</div>
								<div className="team-one__content">
									<h4 className="team-one__name">DR. Anilkumar Devaraboina </h4>
									<p className="team-one__title">M.D & DM</p>
									
								</div>
							</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">
								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-5.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> Yoshita Jonnalagadda </h4>
										<p className="team-one__title">Corporate Employee - TSE</p>
										
									</div>
								</div>
								</div>
								<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">

									<div className="team-one__single">
										<div className="team-one__img">
											<img src={publicUrl + "assets/images/team/team-1-15.jpg"} alt="" />
										</div>
										<div className="team-one__content">
											<h4 className="team-one__name"> Sundar Raj Kumar Mulpuri</h4>
											<p className="team-one__title">Post Graduate - M.A</p>
											
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">

									<div className="team-one__single">
										<div className="team-one__img">
											<img src={publicUrl + "assets/images/team/team-1-14.jpg"} alt="" />
										</div>
										<div className="team-one__content">
											<h4 className="team-one__name">  Uday Kiran Sunkara</h4>
											<p className="team-one__title">
									Post Graduate - M.Sc</p>
											
										</div>
									</div>
								</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">
								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-9.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name">DR.J.Bhaskara Rao </h4>
										<p className="team-one__title">M.P.T (Cardiology). Ph.D</p>
										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">
								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-16.jpg"} alt="" />
									</div>
									<div className="team-one__content">
									<h4 className="team-one__name"> Kondisetti Rajendra </h4>
										<p className="team-one__title">Director ICON Public School</p>
										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">

								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-4.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> DR. Kalapala Praveen </h4>
										<p className="team-one__title">Physiotherapist M.P.T. -Neurosciences (NIMS), M.I.A.P</p>
										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">
								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-12.png"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> Harikrishna Chakrapu  </h4>
										<p className="team-one__title">Technical Lead</p>
										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">
								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-11.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> Saritha KGV</h4>
										<p className="team-one__title">Mentor</p>
										
									</div>
								</div>
							</div>
							 
						 
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">

								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-7.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> Agnimandala Valli Padma </h4>
										<p className="team-one__title">ARDO Team</p>
										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">

								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-8.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> Mounika Chukka </h4>
										<p className="team-one__title">ARDO Team</p>
										
									</div>
								</div>
							</div>
						  
						
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">

								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-10.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> Sunkara Akkaiah Naidu </h4>
										<p className="team-one__title">ARDO Team</p>
										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">
								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-6.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> Praveen Sunkara </h4>
										<p className="team-one__title">ARDO Team</p>
										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6  wow fadeInRight" data-wow-delay="100ms">

								<div className="team-one__single">
									<div className="team-one__img">
										<img src={publicUrl + "assets/images/team/team-1-3.jpg"} alt="" />
									</div>
									<div className="team-one__content">
										<h4 className="team-one__name"> Vijay Kumar Sunkara </h4>
										<p className="team-one__title">ARDO Team</p>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	}
}