import RegisterCard from "../../Cards/RegisterCard/RegisterCard";
import './Register.css'

const Register = () => {
    return (
        <div className="register__auth__container">
            <div className="register__auth">
                <RegisterCard />
            </div>
        </div>
    );
}

export default Register;