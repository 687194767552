import React from 'react';

export default class BlogSectionThree extends React.Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>
				<section className="blog-three">
					<div className="container">
						<div className="section-title text-center">
							<span className="section-title__tagline">Our Blog Post</span>
							<h2 className="section-title__title">We Best To Largest <br /> Of This Blog.</h2>
						</div>
						<div className="row">
							<div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">

								<div className="blog-three__single">
									<div className="blog-three__img">
										<img src={publicUrl + "assets/images/resources/act7.jpg"} alt="" />
									</div>
									<div className="blog-three__content">
										<h3 className="blog-three__title"> GENETIC TEST AND SOCIAL AWARENESS CAMP, JANUARY 2019 </h3>
										<div className='divider'></div>
										<div className="blog-three__person">
											<p>
												Muscular Dystrophy cannot be identified until genetic tests are done. Considered as a rare disease it is important to let people know about it and also to clear the misconceptions people have about the disease. This event is organized to educate and enable people understand about the disease.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">

								<div className="blog-three__single">
									<div className="blog-three__img">
										<img src={publicUrl + "assets/images/resources/gallery7.jpg"} alt="" />
									</div>
									<div className="blog-three__content">
										<h3 className="blog-three__title"> MOTHER’S DAY EVENT, MAY 2019 </h3>
										<div className='divider'></div>
										<div className="blog-three__person">
											<p>
												Mothers are treated as God. To recognize their efforts and as a mark of respect for the tireless efforts they put in to carefully up bring children, ARDO has dedicated this day for all the mothers. Dy.S.P KGV Saritha has been the guest of the day.                                            </p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">

								<div className="blog-three__single">
									<div className="blog-three__img">
										<img src={publicUrl + "assets/images/resources/act3.jpg"} alt="" />
									</div>
									<div className="blog-three__content">
										<h3 className="blog-three__title"> RARE DISEASES DAY, FEBRUARY, 2019 </h3>
										<div className='divider'></div>
										<div className="blog-three__person">
											<p>
												The objective of this event was to raise awareness amongst the general public and also decision-makers about muscular dystrophy and its impact on patients' lives. Through this ARDO aimed at seeking help from the government and other individual donors for the wellbeing of the patients.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	}
}