import React from 'react';

export default class Childwelfare extends React.Component {
 
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <section className="about-one about-three">
                    <div className="container">
                        <div className='about-one__right'>
                    <div class="section-title text-center"> 
                        <h2 class="section-title__title"> Bright Beginnings – Child Welfare Programme</h2>
                        </div>
                        
                            <div className='row'>
                                <div className='col-lg-6 scr-wid'>
                                    <div className='Childwelfare'>
                                        <img src={publicUrl + "assets/images/resources/books.png"} alt="" />
                                        <h3> Books & Stationary</h3>
                                        <p>Providing kids with books and stationary to encourage education among them. It also acts as a boost for them to not leave their education due to family situation.</p>
                                    </div>
                                </div>
                                <div className='col-lg-6 scr-wid'>
                                <div className='Childwelfare'>
                                        <img src={publicUrl + "assets/images/resources/educational.png"} alt="" />
                                        <h3> Educational Devices</h3>
                                        <p>Providing kids with child-friendly
tablets with parental controls to
enable them wider scope for
knowledge and access
information beyond boundries of
the four walls.</p>
                                    </div>
                                </div>
                                <div className='col-lg-6 scr-wid'>
                                <div className='Childwelfare'>
                                        <img src={publicUrl + "assets/images/resources/buddhism.png"} alt="" />
                                        <h3> Pranayama & Anapana
Session </h3>
                                        <p>Children are taught Anapana
and Pranayama to ensure
healthy life through breathing
exercises and meditation. These
sessions aim at enriching kids
mind and strengthen their soul.</p>
                                    </div>
                                </div>
                                <div className='col-lg-6 scr-wid'>
                                <div className='Childwelfare'>
                                        <img src={publicUrl + "assets/images/resources/games.png"} alt="" />
                                        <h3> Recreational Games & Puzzles</h3>
                                        <p>Support children with
recreational game and
puzzle sets to keep them
engaged and happy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                         
                    </div>
                </section>
                </>
                )
            }
        }