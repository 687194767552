import React from 'react';

export default class FeatureTwo extends React.Component {
    render() {
        return (
            <>
                <section className="feature-one">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-4 col-lg-4 wow fadeInLeft" data-wow-delay="100ms">

                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className="icon-donation-1"></span>
                                        </div>
                                        <h3 className="feature-one__title">
                                            Become a Volunteer </h3>
                                    </div>
                                    <p className="feature-one__text">
                                        You can give back love to the society and still learn new skills to improve the society.</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">

                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className="icon-heart"></span>
                                        </div>
                                        <h3 className="feature-one__title"> Gift an individual </h3>
                                    </div>
                                    <p className="feature-one__text">
                                        A Gift equal to a smile :)</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 wow fadeInRight" data-wow-delay="300ms">

                                <div className="feature-one__single">
                                    <div className="feature-one__top">
                                        <div className="feature-one__icon">
                                            <span className="icon-help"></span>
                                        </div>
                                        <h3 className="feature-one__title"> Become a Sponsor </h3>
                                    </div>
                                    <p className="feature-one__text">Sponsor an individual to improve their quality of life which also offers them a renewed sense of hope and possibility.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        )
    }
}