import React from 'react';

export default class ProjectOne extends React.Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>
				<section className="project-one">
					<div className="container">
						<div className="section-title text-center">
							<span className="section-title__tagline">Our Causes Events</span>
							<h2 className="section-title__title">We Popular To Provide <br /> Best Projects.</h2>
						</div>
						<div className="row">
							<div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="100ms">

								<div className="project-one__single">
									<div className="project-one__img">
										<img src={publicUrl + "assets/images/resources/act7.jpg"} alt="" />
										<div className="project-one__content">
											<p className="project-one__sub-title">GENETIC TEST AND SOCIAL AWARENESS CAMP, JANUARY 2019</p>
											<h3 className="project-one__title"> Muscular Dystrophy cannot be identified until genetic tests are done. Considered as a rare disease it is important to let people know about it and also to clear the misconceptions people have about the disease. This event is organized to educate and enable people understand about the disease.</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="200ms">

								<div className="project-one__single">
									<div className="project-one__img">
										<img src={publicUrl + "assets/images/resources/act3.jpg"} alt="" />
										<div className="project-one__content">
											<p className="project-one__sub-title">RARE DISEASES DAY, FEBRUARY, 2019</p>
											<h3 className="project-one__title"> The objective of this event was to raise awareness amongst the general pubflic and also decision-makers about muscular dystrophy and its impact on patients' lives. Through this ARDO aimed at seeking help from the government and other individual donors for the wellbeing of the patients.
											</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="200ms">

								<div className="project-one__single">
									<div className="project-one__img">
										<img src={publicUrl + "assets/images/resources/gallery7.jpg"} alt="" />
										<div className="project-one__content">
											<p className="project-one__sub-title">MOTHER’S DAY EVENT, MAY 2019</p>
											<h3 className="project-one__title"> Mothers are treated as God. To recognize their efforts and as a mark of respect for the tireless efforts they put in to carefully up bring children, ARDO has dedicated this day for all the mothers. Dy.S.P KGV Saritha has been the guest of the day.
											</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="200ms">

								<div className="project-one__single">
									<div className="project-one__img">
										<img src={publicUrl + "assets/images/resources/act4.jpg"} alt="" />
										<div className="project-one__content">
											<p className="project-one__sub-title">Muscular Dystrophy Awareness Day 2023</p>
											<h3 className="project-one__title"> The objective of this event was to raise awareness amongst the general public and also decision-makers about muscular dystrophy and its impact on patients' lives. Through this ARDO aimed at seeking help from the government and other individual donors for the wellbeing of the patients.
											</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="300ms">

								<div className="project-one__single">
									<div className="project-one__img">
										<img src={publicUrl + "assets/images/resources/gallery5.jpg"} alt="" />
										<div className="project-one__content">
											<p className="project-one__sub-title">COVID-19 FOOD DISTRIBUTION DRIVE</p>
											<h3 className="project-one__title"> While the fear of Covid-19 has locked the entire globe, ARDO has chosen to fearlessly rise and carry out food donation drive to feed the hungry homeless destitute, poor households along with distribution of masks and sanitizers to the covid frontline workers, the police authorities.
											</h3>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="200ms">

								<div className="project-one__single">
									<div className="project-one__img">
										<img src={publicUrl + "assets/images/resources/act7.jpg"} alt="" />
										<div className="project-one__content">
											<p className="project-one__sub-title">RARE DISEASES DAY, FEBRUARY 2021</p>
											<h3 className="project-one__title"> This event has been organized to distribute electric and manual wheelchairs to some patients and has been a platform to discuss common right of those suffering and the benefits they are entitled to by the government and the issues hindering the pensioning processes. </h3>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		)
	}
}