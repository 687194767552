import React from 'react'; 

export default class Services extends React.Component {
     
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        

        return (
            <>

                <section className="about-two Vision-one about-three">
                    <div className="container">
                    <div className="section-title text-center"> 
                            <h2 className="section-title__title">Sowjanya Sahyoga-Women Welfare</h2>
                        </div>
                        <div className="row"> 
                            <div className='col-lg-4'>
                                <div className='womenwelfare-box'>
                                <img src={publicUrl + "assets/images/resources/hygiene.png"} alt="" />
                                <div className='womenwelfare-text'>
                                    <h2>Health & Hygiene</h2>
                                    <p>Provide monthly kit consisting
                                    of basic menstrual
                                    neccesities.
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='womenwelfare-box'> 
                                <img src={publicUrl + "assets/images/resources/meditation.png"} alt="" />
                                <div className='womenwelfare-text'>
                                    <h2>Health & Hygiene</h2>
                                    <p>Women are taught Anapana
and Pranayama to ensure
healthy life through breathing
exercises and meditation.
These sessions aim at enriching
women’s mind and strengthen
their soul.

                                    </p>
                                </div>
                                </div> 
                            </div>
                            <div className='col-lg-4'>
                            <div className='womenwelfare-box'> 
                                <img src={publicUrl + "assets/images/resources/healthcare.png"} alt="" />
                                <div className='womenwelfare-text'>
                                    <h2>Health & Hygiene</h2>
                                    <p>Organize health care sessions to
highlight the importance of
hygiene and provide various other
health tips. In future we also aim at
providing mental health
counselling sessions as required.
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
             </>
        )
    }
}