import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';

import FooterOne from '../common/footer/FooterOne';

const Events = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb
                heading="Events"
                currentPage="Events"
            />
            <FooterOne />
        </>
    )
}

export default Events;