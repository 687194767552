import React, { useState } from "react";
import { Form, Spinner } from 'react-bootstrap';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import useRazorpay from "react-razorpay";

const DonateForm = () => {
    const navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    const [state, setState] = useState({
        donationAmount: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        comment: "",
        cardNumber: "",
        expiryDate: "",
        cardCode: "",
        billingAddress: "",
        city: "",
        paymentCountry: "",
        errors: {},
        submitting: false,
        isSpinner: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
            errors: {
                ...prevState.errors,
                [name]: "",
            },
        }));
    };
    const retrivedtoken = localStorage.getItem('token');

    const validateForm = () => {
        const newErrors = {};

        if (state.donationAmount.trim() < 100) {
            newErrors.donationAmount = "Donation amount must be greater than or equal to 100";
        }

        if (state.firstName.trim() === "") {
            newErrors.firstName = "First Name is required";
        }

        if (state.lastName.trim() === "") {
            newErrors.lastName = "Last Name is required";
        }

        if (state.email.trim() === "") {
            newErrors.email = "Email is required";
        }

        if (state.comment.trim() === "") {
            newErrors.comment = "Comment is required";
        }

        if (state.address.trim() === "") {
            newErrors.address = "Address is required";
        }

        if (state.country.trim() === "") {
            newErrors.country = "Country is required";
        }

        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(state.phone)) {
            newErrors.phone = "Invalid phone number";
        }

        const cardNumberRegex = /^\d{16}$/;
        if (!cardNumberRegex.test(state.cardNumber)) {
            newErrors.cardNumber = "Invalid card number";
        }
        const expiryDateRegex = /^(0[1-9]|1[0-2]) \/ \d{2}$/;
        if (!expiryDateRegex.test(state.expiryDate)) {
            newErrors.expiryDate = "Invalid expiry date";
        }
        const cardCodeRegex = /^\d{3}$/;
        if (!cardCodeRegex.test(state.cardCode)) {
            newErrors.cardCode = "Invalid card code";
        }
        if (state.billingAddress.trim().length < 5) {
            newErrors.billingAddress = "Billing address is too short";
        }

        if (state.city.trim().length < 2) {
            newErrors.city = "City name is too short";
        }
        if (state.paymentCountry.trim() === "") {
            newErrors.paymentCountry = "Payment Country is required";
        }

        setState((prevState) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                ...newErrors,
            },
        }));

        return Object.values(newErrors).every((error) => !error);
    };

    const DonateNow = async () => {
        setState({ isSpinner: true })

        const { donationAmount } = state;

        if (!donationAmount || donationAmount <= 100) {
            alert('Please enter a valid donation amount.');
            return;
        }

        try {
            const orderOptions = {
                amount: donationAmount * 1,
                currency: 'INR',
            };
            const url = 'http://16.171.216.234:4000/ardo/api/payment/generateOrder';

            const orderResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderOptions),
            });

            if (!orderResponse.ok) {
                throw new Error(`HTTP error! status: ${orderResponse.status}`);
            }

            const orderData = await orderResponse.json();

            const razorpayOptions = {
                key: 'rzp_test_cOZ2p8So6mueMC',
                amount: donationAmount ? donationAmount : 100 * 1,
                currency: 'INR',
                name: 'ARDO',
                description: 'Donation for a Cause',
                image: 'assets/images/icon/logoss.png',
                order_id: orderData?.order?.id,
                handler: async (response) => {
                    if (response.razorpay_payment_id) {
                        try {

                            const data = {
                                "razorpay_payment_id": response?.razorpay_payment_id,
                                "razorpay_order_id": "order_N4CiHrfhtfcYIA",
                                "razorpay_signature": "10fe170876c2c61e5bb98a36d860e79ec172eee2627a2b189e11afc8f9b8f919"
                            };

                            const url = 'http://16.171.216.234:4000/ardo/api/payment/verify';
                            const response = await fetch(url, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${retrivedtoken}`
                                },
                                body: JSON.stringify(data)
                            });


                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }

                            const result = await response.json();
                            toast.success('Payment successful!');
                            await handleRegisterAPI(response.razorpay_payment_id);
                        } catch (error) {
                            toast.error(error.response?.data.message);
                        }

                    } else {
                        toast.error('Payment failed. Please try again.');
                    }
                },
                prefill: {
                    name: state.firstName,
                    email: state.email,
                    contact: state.phone,
                },
                notes: {
                    donationType: 'General Donation',
                },
            };

            const rzp = new Razorpay(razorpayOptions);
            rzp.open();
        } catch (error) {
            console.error('Error processing payment:', error);
        }
        setState({ isSpinner: false })
    };


    const handleRegisterAPI = async (razorpayPaymentId) => {
        const {
            firstName,
            lastName,
            email,
            phone,
            address,
            city,
            country,
            comment,
            donationAmount,
        } = state;

        const data = {
            firstName,
            lastName,
            email,
            phoneNumber: phone,
            address,
            city,
            country,
            comment,
            donationValue: donationAmount,
            orderId: razorpayPaymentId,
        };

        const url = 'http://16.171.216.234:4000/ardo/api/donations/add';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${retrivedtoken}`
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            toast.success(result?.message);

            if (result?.status === "200" || result?.status === 200) {
                navigate('/');
            }
        } catch (error) {
            toast.error(error.response?.data.message);
        }

        if (state.submitting) {
            return;
        }

        setState({ submitting: true });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (state.submitting) {
            return;
        }

        const isFormValid = validateForm();

        if (isFormValid) {
            DonateNow();
        }
    };

    return (
        <div className='container'>
            <div className="row">
                <div className="col-xl-8 col-lg-7 donate_noww">
                    <div className="donate-now__left">
                        <form className="donate-now" onSubmit={handleSubmit}>
                            <div className="donate-now__enter-donation">
                                <h3 className="donate-now__title">Enter Your Donation</h3>
                                <div className="donate-now__enter-donation-input">
                                    <input
                                        type="text"
                                        name="donationAmount"
                                        placeholder="Enter amount"
                                        onChange={handleChange}
                                    />
                                    <div className="selectpicker">₹</div>
                                </div>{" "}
                                {state.errors.donationAmount && (
                                    <p className="error-message">{state.errors.donationAmount}</p>
                                )}
                            </div>
                            <div className="donate-now__personal-info-box">
                                <h3 className="donate-now__title">Personal Info</h3>
                                <div className="donate-now__personal-info-form">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input
                                                    type="text"
                                                    placeholder="First Name"
                                                    name="firstName"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.firstName && (
                                                    <p className="error-message">{state.errors.firstName}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input
                                                    type="text"
                                                    placeholder="Last Name"
                                                    name="lastName"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.lastName && (
                                                    <p className="error-message">{state.errors.lastName}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input
                                                    type="email"
                                                    placeholder="Email Address"
                                                    name="email"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.email && (
                                                    <p className="error-message">{state.errors.email}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    name="phone"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.phone && (
                                                    <p className="error-message">{state.errors.phone}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <input
                                                    type="text"
                                                    placeholder="Address"
                                                    name="address"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.address && (
                                                    <p className="error-message">{state.errors.address}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <Form.Select
                                                    className="selectpicker"
                                                    aria-label="Default select example"
                                                    name="country" // Add this line
                                                    onChange={handleChange}
                                                > 
                                                    <option disabled selected>Country</option>
                                                    <option value="1">USA</option>
                                                    <option value="2">England</option>
                                                    <option value="3">Australia</option>
                                                </Form.Select>
                                                {state.errors.country && (
                                                    <p className="error-message">{state.errors.country}</p>
                                                )}
                                            </div>
                                        </div>
                                        {/* Add code for other input fields */}
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="donate-now__personal-info-input donate-now__personal-info-message-box">
                                                <textarea
                                                    name="comment"
                                                    placeholder="Write a Comment"
                                                    onChange={handleChange}
                                                ></textarea>
                                                {state.errors.comment && (
                                                    <p className="error-message">{state.errors.comment}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="donate-now__payment-info">
                                <h3 className="donate-now__title">Payment Info</h3>
                                <div className="donate-now__payment-info-form">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="donate-now__payment-info-input">
                                                <input
                                                    type="text"
                                                    placeholder="Card Number"
                                                    name="cardNumber"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.cardNumber && (
                                                    <p className="error-message">
                                                        {state.errors.cardNumber}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__payment-info-input">
                                                <input
                                                    type="text"
                                                    placeholder="MM / YY"
                                                    name="expiryDate"
                                                    id="datepicker"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.expiryDate && (
                                                    <p className="error-message">
                                                        {state.errors.expiryDate}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__payment-info-input">
                                                <input
                                                    type="text"
                                                    placeholder="Card Code ( CVC )"
                                                    name="cardCode"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.cardCode && (
                                                    <p className="error-message">{state.errors.cardCode}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__payment-info-input">
                                                <input
                                                    type="text"
                                                    placeholder="Billing Address"
                                                    name="billingAddress"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.billingAddress && (
                                                    <p className="error-message">{state.errors.billingAddress}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__payment-info-input">
                                                <input
                                                    type="text"
                                                    placeholder="City"
                                                    name="city"
                                                    onChange={handleChange}
                                                />
                                                {state.errors.city && (
                                                    <p className="error-message">{state.errors.city}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="donate-now__personal-info-input">
                                                <Form.Select
                                                    className="selectpicker"
                                                    aria-label="Default select example"
                                                    name="paymentCountry" // Add this line
                                                    onChange={handleChange}
                                                >
                                                    <option disabled selected>Country</option>
                                                    <option value="1">USA</option>
                                                    <option value="2">England</option>
                                                    <option value="3">Australia</option>
                                                </Form.Select>
                                                {state.errors.paymentCountry && (
                                                    <p className="error-message">{state.errors.paymentCountry}</p>
                                                )}
                                            </div>
                                        </div>
                                        {/* Add code for other input fields */}
                                    </div>
                                    <div className="donate-now__payment-info-btn-box">
                                        <button
                                            type="submit"
                                            className="thm-btn donate-now__payment-info-btn"
                                        >
                                            Donate now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            {
                state.isSpinner && (
                    <Spinner animation="border" role="status" variant="success" className="spinner">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )

            }

        </div>
    );
}

export default DonateForm;
