import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  phoneNumber: '',
  dob: '',
  gender: '',
  address: '',
  addresstwo: '',
  country: '',
  city: '',
  region: '',
  pin: '',
  amount: '',
  errors: {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    dob: '',
    gender: '',
    address: '',
    country: '',
    city: '',
    region: '',
    pin: '',
    amount: '',
  },
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    handleChange: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    validateForm: (state, action) => {
      const errors = {};
      if (!state.name) {
        errors.name = 'Name is required';
      }
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!state.email || !emailPattern.test(state.email)) {
        errors.email = 'Invalid email';
      }
      const phonePattern = /^[789]\d{9}$/;
      if (!state.phoneNumber || !phonePattern.test(state.phoneNumber)) {
        errors.phoneNumber = 'Invalid phone number';
      }
      if (state.password.length < 6 || !state.password) {
        errors.password = 'Password must be 6 characters';
      }
      if (state.password !== state.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
      }

      if (!state.dob) {
        errors.dob = 'Date of birth is required';
      }

      if (!state.gender) {
        errors.gender = 'Gender is required';
      }
      if (state.amount < 100) {
        errors.amount = "Donation amount must be greater than or equal to 100";
      }
      if (!state.address) {
        errors.address = 'Address is required';
      }

      if (!state.country) {
        errors.country = 'Country is required';
      }

      if (!state.city) {
        errors.city = 'City is required';
      }

      if (!state.region) {
        errors.region = 'Region is required';
      }

      if (!state.pin) {
        errors.pin = 'Pin code is required';
      }
      state.errors = errors;
    },
    resetForm: (state) => {
      return initialState;
    },
  },

})

export const { handleChange, validateForm, resetForm } = registerSlice.actions

export default registerSlice.reducer