import React from 'react';

export default class ContactInfo extends React.Component {
    render() {
        return (
            <>
                <section className="contact-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">

                                <div className="contact-info__single">
                                    <h4 className="contact-info__title">About</h4>
                                    <p className="contact-info__text">Amaravati Rare Diseases Organisation with registered number Or...</p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">

                                <div className="contact-info__single">
                                    <h4 className="contact-info__title">Address</h4>
                                    <p className="contact-info__text">Amaravathi , Andhra Pradesh ,<br />
                                        India. </p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">

                                <div className="contact-info__single contact-info__single-3">
                                    <h4 className="contact-info__title">Contact</h4>
                                    <p className="contact-info__email-phone">
                                        <a href="mailto:ardocare@gmail.com"
                                            className="contact-info__email">ardocare@gmail.com</a>
                                        <a href="tel:13077760608" className="contact-info__phone">+91 7093335285</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}