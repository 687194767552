import React, { Component } from "react";
import { Form,Spinner } from 'react-bootstrap';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';


class VolunteerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      donationAmount: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      country: "",
      comment: "",
      errors: {},
      submitting: false,
      isSpinner:false

    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;

  
    this.setState((prevState) => ({
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: "",
      },

    }));
  };

  validateForm = () => {
    const newErrors = {};

 
    if (this.state.donationAmount.trim() === "") {
      newErrors.donationAmount = "Donation amount is required";
    }

 
    if (this.state.firstName.trim() === "") {
      newErrors.firstName = "First Name is required";
    }
 
    if (this.state.lastName.trim() === "") {
      newErrors.lastName = "Last Name is required";
    }
 
    if (this.state.email.trim() === "") {
      newErrors.email = "Email  is required";
    }
 
    if (this.state.comment.trim() === "") {
      newErrors.comment = "Comment  is required";
    }
 
    const phoneRegex = /^\d{10}$/;  
    if (!phoneRegex.test(this.state.phone)) {
      newErrors.phone = "Invalid phone number";
    }
 

    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        ...newErrors,
      },
    }));


 
    return Object.values(newErrors).every((error) => !error);

  };

  handleSubmit = async (e) => {
 
    this.setState( { isSpinner: true })
    e.preventDefault();
    const { navigate } = this.props;
    const retrivedtoken = localStorage.getItem('token');
 

    const url = 'http://16.171.216.234:4000/ardo/api/volunteer/add';
    const token = retrivedtoken; 
    const data = {
      "name": this.state.firstName,
      "email": this.state.email,
      "phoneNumber": this.state.phone,
      "message": this.state.comment
    };
 

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        
      }

      const result = await response.json(); 
    
      toast.success(result?.message);

      if (result?.status === "200" || result?.status === 200) {
        navigate('/');
      }
    } 
    
    catch (error) {
      toast.error(error.response?.data.message || 'Server not found');
      
    
      this.setState({
        donationAmount: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        comment: "",
        errors: {},
        isSpinner: false,
        submitting: false
      });
    }

    if (this.state.submitting) {
      return;
    }

     
    this.setState({ submitting: true });

    this.setState( { isSpinner: false })

  };

  render() {
    const { errors } = this.state;
    return (
      <>
        <form className="donate-now" onSubmit={this.handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-7 donate_noww">
                <div className="donate-now__left">

                  <div className="donate-now__personal-info-box">
                    <h3 className="donate-now__title">Volunteer Info</h3>
                    <div className="donate-now__personal-info-form">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="donate-now__personal-info-input">
                            <input
                              type="text"
                              placeholder="First Name"
                              name="firstName"
                              onChange={this.handleChange}
                            />
                            {errors.firstName && (
                              <p className="error-message">{errors.firstName}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="donate-now__personal-info-input">
                            <input
                              type="text"
                              placeholder="Last Name"
                              name="lastName"
                              onChange={this.handleChange}
                            />
                            {errors.lastName && (
                              <p className="error-message">{errors.lastName}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="donate-now__personal-info-input">
                            <input
                              type="email"
                              placeholder="Email Address"
                              name="email"
                              onChange={this.handleChange}
                            />
                            {errors.email && (
                              <p className="error-message">{errors.email}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="donate-now__personal-info-input">
                            <input
                              type="text"
                              placeholder="Phone Number"
                              name="phone"
                              onChange={this.handleChange}
                            />
                            {errors.phone && (
                              <p className="error-message">{errors.phone}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="donate-now__personal-info-input donate-now__personal-info-message-box">
                            <textarea
                              name="comment"
                              placeholder="Write a Comment"
                              onChange={this.handleChange}
                            ></textarea>
                            {errors.comment && (
                              <p className="error-message">{errors.comment}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="donate-now__payment-info-btn-box">
                    <button type="submit" className="thm-btn donate-now__payment-info-btn" onClick={this.validateForm}>Submit</button>
                  </div>
                </div>
              </div>

            </div>
            { 
            this.state.isSpinner  && (
                <Spinner animation="border" role="status" variant="success"  className="spinner">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )
                
            }
          </div>
        </form>
      </>
    )
  }
}

const VolunteerFormWithNavigation = (props) => {
  const navigate = useNavigate();
  return <VolunteerForm {...props} navigate={navigate} />;
};

export default VolunteerFormWithNavigation;
