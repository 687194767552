import React from 'react';

export default class Muscular extends React.Component {
  
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>
				<section className="about-two ">
					<div className="container">
						<div className="row">
							<div className="col-xl-6">
							<div className="about-one__right">
									<div className="about-one__left-content">
										<div className="section-title text-left"> 
											<h2 className="section-title__title">What is Muscular Dystrophy?</h2>
										</div>
										<p className="about-one__text">Muscular Dystrophy (MD) is a group of genetic-muscle disorder that weaken the musculoskeletal system and hamper locomotion. Muscular dystrophies are characterized by progressive skeletal muscle weakness, defects in muscle proteins, and the death of muscle cells and tissue.</p>
										<ul className="list-unstyled about-one__points">
											<li>
												 
												<div className="text"> 
													<p>There’s no cure for any form of muscular dystrophy. Although, there are physical, occupational therapy etc available, which stabilizes the physical movement for some days, but they are unable to save the victim from death.</p>
												</div>
											</li>
											 
										</ul>
										 
									</div>
								</div>
							</div>
							<div className="col-xl-6">
								<div className='about-one__right'>
										<div className="about-one__img muscular ">
											<img src={publicUrl + "assets/images/resources/Ardo-Image.jpg "} alt="" />
										</div>
									</div>
							</div>
						</div>
				   </div>
				</section>
			</>
		)
	}
}