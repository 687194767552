import '../RegisterCard/RegisterCard.css';
import React, { useState ,useEffect} from 'react';
import axios from 'axios'; import { Link } from 'react-router-dom';
import { useDispatch, useSelector, state } from 'react-redux';
import { toast } from 'react-toastify';
import { handleChange, validateForm, resetForm } from "../../../redux/registrationSlice"
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import useRazorpay from 'react-razorpay';

const RegistrationForm = () => {

  const [isSpinner, setIsSpinner] = useState(false)
  const [Razorpay] = useRazorpay();

  const navigate = useNavigate();
  const userStates = useSelector(state => state.registration)
  const dispatch = useDispatch()

  const DonateNow = async () => {
    setIsSpinner(true);
    try {
      const orderOptions = {
        amount: userStates.amount ? userStates.amount : 100 * 1,
        currency: 'INR',
      };
      const url = 'http://16.171.216.234:4000/ardo/api/payment/generateOrder';
      // const url = 'https://socail-backend-service.onrender.com/ardo/api/payment/generateOrder';

      const orderResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderOptions),
      });

      if (!orderResponse.ok) {
        throw new Error(`HTTP error! status: ${orderResponse.status}`);
      }

      const orderData = await orderResponse.json();

      console.log("order data",orderData)

      const razorpayOptions = {
        key: 'rzp_test_cOZ2p8So6mueMC',  
        amount: userStates.amount ? userStates.amount : 100 * 1,
        currency: 'INR',
        name: 'Your Organization Name',
        description: 'Donation for a Cause',
        image: 'assets/images/icon/logoss.png',  
        order_id: orderData?.order?.id,
        handler: async function (res) { 
        console.log(res); 
          if (res.razorpay_payment_id) {
            // const data = {
            //   "razorpay_payment_id": res.razorpay_payment_id,
            //   "razorpay_order_id": "order_N4CiHrfhtfcYIA",
            //   "razorpay_signature": "10fe170876c2c61e5bb98a36d860e79ec172eee2627a2b189e11afc8f9b8f919"
            // };

            // const url = 'http://16.171.216.234:4000/ardo/api/payment/verify';
            // // const url = 'https://socail-backend-service.onrender.com/ardo/api/payment/verify';
            // const token = '497aa50cc84a53c46831ab7df42072785aac8be5a4abd60915c7bdf60a90f1267d5ba4';
            // console.log(data, token);

            // const response = await fetch(url, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': `Bearer ${token}`
            //   },
            //   body: JSON.stringify(data)
            // }).then(function(response){
            //   console.log("response is" , response);
            // }).catch(function(error){
            //   console.log("error is ",error)
            // });

            try {

              const data = {
                "razorpay_payment_id": res?.razorpay_payment_id,
                "razorpay_order_id": res?.razorpay_order_id,
                "razorpay_signature": res?.razorpay_signature,
                "currency" : orderData?.order?.id,
                "status" : "captured",
                "amount" : orderData?.order?.amount,
                "order_id" : orderData?.order?.id,
                "paymentId"  : res?.razorpay_payment_id,
                "transactionLog" : orderData?.order
              };

              const url = 'http://16.171.216.234:4000/ardo/api/payment/verify';
              // const url = 'https://socail-backend-service.onrender.com/ardo/api/payment/verify';
              const token = '497aa50cc84a53c46831ab7df42072785aac8be5a4abd60915c7bdf60a90f1267d5ba4';
              console.log(data, token);

              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                  "x-razorpay-signature" : res?.razorpay_signature
                },
                body: JSON.stringify(data)
              })
              // .then(function(response){
              //   console.log("response is" , response);

              // }).catch(function(error){
              //   console.log("error is ",error)

              // })

              console.log(response);
              const result = await response.json();
              console.log(result);

              if (!result.status) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }

              // const result = await response.json();
              toast.success('Payment successful!');
              const registerData = {
                name: userStates.name,
                email: userStates.email,
                password: userStates.password,
                confirmPassword: userStates.confirmPassword,
                phoneNumber: userStates.phoneNumber,
                dateOfBirth: userStates.dob,
                gender: userStates.gender,
                address1: userStates.address,
                address2: userStates.addresstwo,
                city: userStates.city,
                country: userStates.country,
                region: userStates.region,
                pincode: userStates.pin,
                amount: userStates.amount,
                orderId: "dummyID"
              }
              const responseRegister = await axios.post('http://16.171.216.234:4000/ardo/api/user/register', registerData);
              // const responseRegister = await axios.post('https://socail-backend-service.onrender.com/ardo/api/user/register', registerData);
              localStorage.setItem('token', responseRegister.data.response.token);
              setIsSpinner(false);
              dispatch(resetForm())
              toast.success('Registration Successful!');
              navigate("/Login");

            } catch (error) {
              setIsSpinner(false);
              console.log("error from payment gateway",error);
              toast.error(error.response?.data.message);
            }

          } else {
            setIsSpinner(false);
            toast.error('Payment failed. Please try again.');
          }
        },
        prefill: {
          name: userStates.name,
          email: userStates.email,
          contact: userStates.phoneNumber,
        },
        notes: {
          donationType: 'General Donation',
        },
      };


      const rzp = new Razorpay(razorpayOptions);
      rzp.open();
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(validateForm())
    if (Object.keys(userStates.errors).length > 0) {
      return;
    }

    try {
      setIsSpinner(true);
      await DonateNow();
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.response?.data.message);
      setIsSpinner(false);
    }
    setIsSpinner(false);
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(handleChange({ name, value }));
    dispatch(validateForm());
  }

  useEffect(() => {
    dispatch(handleChange({ name: 'amount', value: '100' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (

    <div className='login-box'>
      <div className='container'>
        <h6>
        <Link to={'/'} >  <img src={"assets/images/icon/logoss.png"} alt="" /></Link> 
        </h6>
        <h1>Registration Form</h1>
        <form onSubmit={handleSubmit}>
          <div className='row'>

            <div className="col-lg-6">
              <div className='input-box'>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={userStates.name}
                  placeholder="Name"
                  onChange={handleInputChange}

                />
                {userStates.errors.name && (
                  <p className='error'>{userStates.errors.name}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className='input-box'>
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={userStates.email}
                  onChange={handleInputChange}

                />
                <p className="error">{userStates.errors.email}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className='input-box'>
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={userStates.password}
                  onChange={handleInputChange}
                />
                <p className="error">{userStates.errors.password}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className='input-box'>
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={userStates.confirmPassword}
                  onChange={handleInputChange}
                />
                <p className="error">{userStates.errors.confirmPassword}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className='input-box'>
                <label>Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={userStates.phoneNumber}
                  onChange={handleInputChange}
                  placeholder="PhoneNumber"
                />
                <p className="error">{userStates.errors.phoneNumber}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className='input-box'>
                <label>Date of Birth</label>
                <input
                  type="date"
                  name="dob"
                  value={userStates.dob}
                  onChange={handleInputChange}
                />
                <p className="error">{userStates.errors.dob}</p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className='input-boxx'>
                <label>Gender</label>
                <div className='gender'>
                  <input
                    type="radio"
                    id="check-male"
                    name="gender"
                    value="male"
                    checked={userStates.gender === 'male'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="check-male">Male</label>
                  &nbsp;
                  <input
                    type="radio"
                    id="check-female"
                    name="gender"
                    value="female"
                    checked={userStates.gender === 'female'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="check-female">Female</label>
                  &nbsp;
                  <input
                    type="radio"
                    id="check-other"
                    name="gender"
                    value="other"
                    checked={userStates.gender === 'other'}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="check-other">Other</label>
                  <p className="error">{userStates.errors.gender}</p>
                </div>

              </div>
            </div>
            <div className="col-lg-12">
              <div className='input-box'>
                <label>Address</label>
                <input
                  name="address"
                  value={userStates.address}
                  placeholder="Address-1"
                  onChange={handleInputChange}
                />
                <p className="error">{userStates.errors.address}</p>
                <input
                  name="addresstwo"
                  value={userStates.addresstwo}
                  onChange={handleInputChange}
                  placeholder="Address-2"
                />
                <p className="error">{userStates.errors.addresstwo}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className='input-box'>
                <label>Country</label>
                <select name="country" value={userStates.country} onChange={handleInputChange}>
                  <option value="">Country</option>
                  <option value="America">America</option>
                  <option value="Canada">Canada</option>
                  <option value="India">India</option>
                </select>
                <p className="error">{userStates.errors.country}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className='input-box'>
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={userStates.city}
                  onChange={handleInputChange}
                />
                <p className="error">{userStates.errors.city}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className='input-box'>
                <label>Region</label>
                <input
                  type="text"
                  name="region"
                  value={userStates.region}
                  onChange={handleInputChange}
                />
                <p className="error">{userStates.errors.region}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className='input-box'>
                <label>Pin code</label>
                <input
                  type="text"
                  name="pin"
                  value={userStates.pin}
                  onChange={handleInputChange}
                />
                <p className="error">{userStates.errors.pin}</p>
              </div>
            </div>
            {/* <div className="col-lg-12">
              <div className='input-box justify-content-center'>
                <label className='text-center mb-1'>Registration Charges</label>
                <div className="donate-now__enter-donation-input">
                  <input className='bg-white m-0'
                    type="text"
                    name="amount"
                    placeholder="100"
                    onChange={handleInputChange}
                  />
                  <div className="selectpicker m-0">₹</div>
                  <p className="error">{userStates.errors.amount}</p>
                </div>
              </div>
            </div> */}
             <div className="col-lg-12">
              <div className='input-box justify-content-center'> 
                <div className="donate-now__enter-donation-input">
                  <div className='bg-white m-0 register_inpu' >Registration Charges
                  
                  </div>
                  <div className="selectpicker m-0">₹ 100</div> 
                </div>
                  <p className="error">{userStates.errors.amount}</p>
              
              </div>
            </div>
            <button className='login-btn' type="submit">
              Register
            </button>
            {
              isSpinner && (
                <Spinner animation="border" role="status" variant="success" className="spinner">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )

            }
            <Link to="/Login ">
              <div className="CreateAccount">Back to
                Login
              </div>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegistrationForm;
