import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  email: "",
  password: "",
  errors: {
    passwordError: "",
    emailError: "",
  },
  token: null,
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    handleChange: (state, action) => {
      const { name, value } = action.payload;
   
      state[name] = value;
    },
    validateForm: (state) => {
      const errors = {};
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!state.email || !emailPattern.test(state.email)) {
        errors.emailError = 'Invalid email';
      }
      if (state.password.length < 6 || !state.password) {
        errors.passwordError = 'Password must be at least 6 characters';
      }
      state.errors = errors;
    },
    resetForm: (state) => {
      return {
        ...state,
        email: '',
        password: '',
        errors: {
          emailError: '',
          passwordError: '',
        },
      };
    },
  },
})
export const { handleChange, validateForm, resetForm } = loginSlice.actions

export default loginSlice.reducer