import React from 'react';
import { Link } from "react-router-dom";
import Nav from './Nav';
import MobileMenu from './MobileMenu';


export default class HeaderOne extends React.Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <header className="main-header clearfix">
                    <div className="main-header__top clearfix">
                        <div className="container clearfix">
                            <div className="main-header__top-inner clearfix">
                                <div className="main-header__top-left">
                                    <ul className="list-unstyled main-header__top-address">
                                        <li className="none-inmb">
                                            <div className="icon">
                                                <span className="icon-call"></span>
                                            </div>
                                            <div className="text">
                                                <p><a href="tel:5204654544">+91 7093335285</a></p>
                                            </div>
                                        </li>
                                        <li className="none-inm" >
                                            <div className="icon">
                                                <span className="icon-envelope"></span>
                                            </div>
                                            <div className="text">
                                                <p><a href="mailto:ardocare.ap@gmail.com">ardocare.ap@gmail.com</a></p>
                                            </div>
                                        </li>
                                        <li className="none-mb">
                                            <div className="icon">
                                                <span className="icon-place"></span>
                                            </div>
                                            <div className="text">
                                                <p>Amaravathi , Andhra Pradesh , India.</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="main-header__top-right">
                                    <ul className="list-unstyled main-header__top-right-content">
                                        <li><Link to={process.env.PUBLIC_URL + `/Login`}>Login</Link></li>
                                        <li><Link to={process.env.PUBLIC_URL + `/Register`}>Sign Up</Link></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="main-menu clearfix">
                        <div className="container clearfix">
                            <div className="main-menu-wrapper clearfix">
                                <div className="main-menu-wrapper__left">
                                    <div className="main-menu-wrapper__logo">
                                        <Link to={process.env.PUBLIC_URL + `/`}>
                                            <img src={publicUrl + "assets/images/resources/mainlogo.png"} alt="" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="main-menu-wrapper__right">
                                    <div className="main-menu-wrapper__main-menu">
                                        <MobileMenu />
                                        <Nav />
                                    </div>
                                    <Link to={process.env.PUBLIC_URL + `/donate-now`} className="thm-btn main-header__btn">Donate Now</Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </>
        )
    }
}