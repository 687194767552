import React from 'react';

export default class AnapanaSession extends React.Component {
 
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <section className="about-two about-three">
                    <div className="container">
                        <div className='about-one__right'>
                    <div class="section-title text-center"> 
                        <h2 class="section-title__title"> Snapshots from Bright Beginnings – Anapana Session</h2>
                        </div>
                        <div className='row'>

                            <div className='col-lg-4'>
                                <div className='AnapanaSession-image'>
                                <img src={publicUrl + "assets/images/resources/AnapanaSession1.png"} alt="" />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='AnapanaSession-image'>
                                <img src={publicUrl + "assets/images/resources/AnapanaSession2.png"} alt="" />
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='AnapanaSession-image'>
                                <img src={publicUrl + "assets/images/resources/AnapanaSession3.png"} alt="" />
                                </div>
                            </div>

                        </div>

                        </div>
                        </div>
                        </section>
                        </>
        )
    }
}