import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import AboutOne from '../components/about/AboutOne';
import TeamOne from '../components/team/TeamOne';
import Muscular from '../components/Muscular/Muscular'
import FooterOne from '../common/footer/FooterOne';
import Vision from '../components/Vision/Vision';
import Message from '../components/Message/Message';
import Services from '../components/Services/Services';
import Womenwelfare from '../components/Womenwelfare/Womenwelfare';
import Childwelfare from '../components/Childwelfare/Childwelfare';
import Prospects from '../components/Prospects/Prospects'
import AnapanaSession from '../components/AnapanaSession/AnapanaSession';

const About = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb
                heading="About Us"
                currentPage="About"
            /> 
            <AboutOne />
            <Muscular />
            <Vision />
            <Message />
            <Services />
            <Womenwelfare />
            <Childwelfare />
            <AnapanaSession />
            <Prospects />
            <TeamOne />
            <FooterOne />
        </>
    )
}

export default About;