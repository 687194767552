import React from "react"; 
import TeamOne from "../components/team/TeamOne"; 
const HomeTwo = () => {
    return (
        <> 
            <TeamOne /> 
        </>
    )
}

export default HomeTwo;