import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import HeroOne from "../components/hero/HeroOne"; 
import FeatureTwo from "../components/features/FeatureOne";
import AboutOne from "../components/about/AboutOne";
import CausesFour from "../components/causes/CausesFour";
import ProjectOne from "../components/project/ProjectOne";

import TeamOne from "../components/team/TeamOne";

import BlogSectionThree from '../components/blog/BlogSectionThree';
import FooterOne from '../common/footer/FooterOne';
import BecomeVolunteer from '../components/BecomeVolunteer/BecomeVolunteer';


const HomeDefault = () => {
    return (
        <>
            <HeaderOne />
            <HeroOne /> 
            <FeatureTwo />
            <AboutOne />
            <CausesFour />
            <BecomeVolunteer />
            <ProjectOne /> 
            <TeamOne /> 
            <BlogSectionThree /> 
            <FooterOne />
        </>
    )
}

export default HomeDefault;