import React from 'react';

export default class AboutOne extends React.Component {
 
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>
				<section className="about-two about-three">
					<div className="container">
					<div class="section-title text-center"> 
						<h2 class="section-title__title"> Director’s Message</h2>
						</div>
						
						<div className='about-one__right'>
							 <div className='director-message'>
								<div className="row">
									<div className='col-lg-5 scr-wid'>
								   <div className='director-message-image'>
								   <img src={publicUrl + "assets/images/team/team-1-1.jpg "} alt="" />
								   </div>
									</div>
									<div className='col-lg-7 scr-wid'>
										<p className='message-para'>
										They see my disability, I see my ability. They called me disabled, I call myself
										differently abled. I was 12 when I was diagnosed with Muscular dystrophy. It all
										began with walking on my toes, not understanding why my muscles were growing
										weaker and losing their strength. I felt like a captive within these four walls. I was
										even more crushed to know that there was no definitive cure or medicine that could
										relive me from this pain and suffering. And when my world was falling and my hope
										was depleting, I learnt that there are other people out there who are suffering like
										me. That’s when I decided that I am going to fight my fears and help these people. A
										small drop can create ripples in the ocean. Support us in helping people like me and
										make their lives a little better. Live and let live <a href=''> – Shoba Rani.</a>
										</p>
									</div>
								</div>
							</div>
							</div>
					</div>
				</section>
				</>
				)
			}
		}