import React from 'react';
import { Link } from 'react-router-dom';

export default class CausesFour extends React.Component {
	componentDidMount() {

		const $ = window.$;

		if ($(".count-bar").length) {
			$(".count-bar").appear(
				function () {
					var el = $(this);
					var percent = el.data("percent");
					$(el).css("width", percent).addClass("counted");
				}, {
				accY: -50
			}
			);
		}

	}
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>
				<section className="causes-one">
					<div className="container">
						<div className="section-title text-center">
							<span className="section-title__tagline">Our Causes Events</span>
							<h2 className="section-title__title">We Popular To Provide <br /> Of Experience.</h2>
						</div>
						<div className="row">

							<div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">

								<div className="causes-one__single">
									<div className="causes-one__img">
										<img src={publicUrl + "assets/images/resources/gallery5.jpg"} alt="" />
									</div>
									<div className="causes-one__content-box">
										<div className="causes-one__donate-btn-box">
											<Link to={process.env.PUBLIC_URL + `/donate-now`} className="thm-btn causes-one__donate-btn">Donate Now</Link>
										</div>
										<div className="causes-one__content">
											<h3 className="causes-one__title"> Wheelchairs For Locomotion
											</h3>

											<div>
												<p className="padding-bottom">
													Distribution of electrical battery wheelchairs and also lifting machines for locomotion on a larger scale to ensure they are not bedridden the whole life.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">

								<div className="causes-one__single">
									<div className="causes-one__img">
										<img src={publicUrl + "assets/images/resources/act7.jpg"} alt="" />
									</div>
									<div className="causes-one__content-box">
										<div className="causes-one__donate-btn-box">
											<Link to={process.env.PUBLIC_URL + `/donate-now`} className="thm-btn causes-one__donate-btn">Donate Now</Link>
										</div>
										<div className="causes-one__content">
											<h3 className="causes-one__title"> Food For Survival
											</h3>

											<div>
												<p className="padding-bottom">
													ARDO has chosen to fearlessly rise and carry out food donation drive to feed the hungry homeless destitute, poor households.                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">

								<div className="causes-one__single">
									<div className="causes-one__img">
										<img src={publicUrl + "assets/images/resources/gallery7.jpg"} alt="" />
									</div>
									<div className="causes-one__content-box">
										<div className="causes-one__donate-btn-box">
											<Link to={process.env.PUBLIC_URL + `/donate-now`} className="thm-btn causes-one__donate-btn">Donate Now</Link>
										</div>
										<div className="causes-one__content">
											<h3 className="causes-one__title"> ORGANIZING AWARENESS CAMPS
											</h3>

											<div>
												<p className="padding-bottom">
													Awareness plays a key role in our approach to improving access to healthcare. We seek to empower medical professionals and patients with appropriate tools, information and skills so that they can make decisions on prevention, diagnosis, treatment, care, and support.                                                </p>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</section>
			</>
		)
	}
}