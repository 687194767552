import React from "react";
import LoginCard from '../../Cards/LoginCard/LoginCard';
import './Login.css';



const Login = () => {
    return ( 
        <div className="login__auth__container">
            <div className="login__auth">
                <LoginCard />
            </div>
        </div>
    );
}

export default Login;


