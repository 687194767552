import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import './LoginCard.css';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { handleChange, resetForm, validateForm } from '../../../redux/loginSlice';
import { useNavigate } from 'react-router-dom';
import axios from "axios"
function LoginValidationForm() {

  const [isSpinner,setIsSpinner]=useState(false)
  
  const navigate = useNavigate();
  const loginData = useSelector(state => state.login)
  const dispatch = useDispatch()

  const handleInputChange = (e) => {
    const { name, value } = e.target
    dispatch(handleChange({ name, value }))
    dispatch(validateForm());
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(validateForm());

    if (Object.keys(loginData.errors).length > 0) {
      return;
    }
    try {
      const data = {
        email: loginData.email,
        password: loginData.password
      }
      setIsSpinner(true);
      const response = await axios.post('http://16.171.216.234:4000/ardo/api/user/login', data);
      // const response = await axios.post('https://socail-backend-service.onrender.com/ardo/api/user/login', data);
      localStorage.setItem('token', response.data.resoonse.token);
       
      dispatch(resetForm())
      toast.success('Login Successful!');
      response.data.resoonse.token && navigate("/")
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.response?.data.message)
    }

    setIsSpinner(false);
  };
  return (
    <div>
      <div className='login-box'>
        <div className='container'>
          <h6  >
          <Link to={'/'} >   <img src={"assets/images/icon/logoss.png"} alt="" /> </Link>
          </h6>
          <form onSubmit={handleSubmit}>
            <h1>Login</h1>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='input-box'>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={loginData.email} placeholder="Name"
                    onChange={handleInputChange} />
                  <span className="error">{loginData.errors.emailError}</span>
                </div>
              </div>
              <div className='col-lg-12'><div className='input-box'>
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={loginData.password} placeholder="Password"
                  onChange={handleInputChange}
                />
                <span className="error">{loginData.errors.passwordError}</span>
              </div>
              </div>
            </div>
            {/* <Link to="/">
              <div className="Forgot_Password">
                Forgot Password?
              </div>
            </Link> */}

            <button className='login-btn' type="submit">Login</button>

            <Link to="/Register">
              <div className="CreateAccount">Create Account!
                Register
              </div>
              { 
            isSpinner  && (
                <Spinner animation="border" role="status" variant="success"  className="spinner">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )
                
            }
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginValidationForm;