import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import FooterOne from '../common/footer/FooterOne';

class Privacy extends React.Component {
    render() {
        return (
            <>
                <HeaderOne />
                <Breadcrumb
                    heading="Cancellation & Refund"
                    currentPage="Cancellation & Refund  "
                />
                <div className="terms-container container">
                    <p>Last updated on Dec 5th 2023</p>
                    <h2 className="h2"> </h2>
                    <p>
                    Thank you for choosing Amaravati Rare Diseases Organisation. We strive to provide you with the best products/services, but we understand that there may be occasions where you need to cancel or return an item. Our cancellation and refund policy is designed to make the process straightforward and fair.
                     </p>
                    
                    <h2 className="h2">Return and Exchange Time Frame:</h2>
                    <p>
                    - Products/Services can be returned or exchanged within 30 days from the date of purchase.
                   </p>
                   <p>
                    - To be eligible for a return/exchange, the item must be unused and in the same condition as you received it.
                   </p>
                    <h2 className="h2">Initiating a Return or Exchange:</h2>
                    <p>
                    - To initiate a return or exchange, please contact our customer service team at   ardocare@gmail.com . 
                    </p>
                    <p>
                    - In your email, provide your order number, a brief reason for the return/exchange, and any relevant details about the item. 
                    </p>

                    <h2 className="h2">Refund Processing Time:</h2>
                    <p>
                    - Once your return/exchange request is received, we will process it within  15  business days.
                    </p>
                    <p>
                    - Refunds will be issued to the original payment method used for the purchase.
                    </p>
                    <p>
                    - If you are exchanging an item, the replacement will be shipped after the return is received and processed.
                    </p>
                    <h2 className="h2">Conditions for Returns/Exchanges:</h2>
                    <p>
                    - Items must be in their original condition with all tags and packaging intact.
                    </p>
                    <p>
                    - We reserve the right to refuse returns/exchanges that do not meet these criteria.
                    </p>
                   
                    <h2 className="h2">Changes to This Policy:</h2>
                    <p>
                    - Amaravati Rare Diseases Organisation reserves the right to modify or update this policy at any time. Changes will be effective immediately upon posting to the website.
                    </p>
                    <h2 className="h2"> </h2>
                    <p>
                    We appreciate your understanding and cooperation with our cancellation and refund policy. If you have any questions or concerns, please don't hesitate to reach out to our customer service team at  ardocare@gmail.com .
                    </p>
                    <h2 className="h2"> </h2>
                    <p>
                    Thank you for choosing Amaravati Rare Diseases Organisation!
                     </p>
                     <h2 className="h2"> </h2>
                     <p>
                     Company Name: Amaravati Rare Diseases Organisation
                     </p>
                     <p>
                     Address: Amaravathi , Andhra Pradesh , Bharat..
                     </p>
                     <p>
                     Amaravati, Andhra Pradesh, 522020
                     </p>
                      <p>
                      Phone Number: +91 70933 35285
                     </p>
                     <p>
                     Email: ardocare@gmail.com
                     </p>

                </div>
                <FooterOne />
            </>
        );
    }
}

export default Privacy;
