import React from 'react';

export default class Prospects extends React.Component {
 
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                <section className="about-one about-three">
                    <div className="container">
                        <div className='about-one__right'>
                        <div class="section-title text-center"> 
                        <h2 class="section-title__title">Your Goals</h2>
                        </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 scr-coll'>
                                    <div className='prospects-box'>
                                        <div className='prospects-image'>
                                        <img src={publicUrl + "assets/images/resources/home-care.png"} alt="" />
                                            <h3>Care home </h3>
                                        </div>
                                        <p>ARDO aims at starting a care
                                    home for those suffering from
                                    Muscular dystrophy with all
                                    amenities and 24/7 access to
                                    physio-therapy and doctor
                                    support.</p>

                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 scr-coll'>
                                    <div className='prospects-box'>
                                        <div className='prospects-image'>
                                        <img src={publicUrl + "assets/images/resources/Pensions.png"} alt="" />
                                            <h3>Pensions </h3>
                                        </div>
                                        <p>Help in getting pensions and PwD benefits from government.</p>

                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 scr-wid'>
                                    <div className='prospects-box'>
                                        <div className='prospects-image'>
                                        <img src={publicUrl + "assets/images/resources/weel-chair.png"} alt="" />
                                            <h3>Wheelchairs </h3>
                                        </div>
                                        <p>Nothing like having the freedom
                                        of moving by yourself. To give
                                        this freedom to everyone
                                        enrolled with ARDO, we aim at
                                        providing electric wheel chairs
                                        to all.</p>

                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 scr-wid'>
                                    <div className='prospects-box'>
                                        <div className='prospects-image'>
                                        <img src={publicUrl + "assets/images/resources/Fundraise.png"} alt="" />
                                            <h3>Fundraise campaigns  </h3>
                                        </div>
                                        <p>ARDO is looking forward to carry
                                            out multiple fun filled and also
                                            awareness based fundraise
                                            campaigns involving public
                                            along with those suffering from
                                            muscular dystrophy to bring this
                                            into limelight.</p>

                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 scr-wid'>
                                    <div className='prospects-box'>
                                        <div className='prospects-image'>
                                        <img src={publicUrl + "assets/images/resources/Child-Welfare.png"} alt="" />
                                            <h3>Women and Child Welfare</h3>
                                        </div>
                                        <p>We also aim at introducing initiatives
                                            to encourage children in education
                                            and in other extra curricular activities.
                                            We also aim at improving women’s
                                            health alongside making them to fall
                                            out of their routine lives by introducing
                                            them to a skill or habit that can earn
                                            them a living.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}