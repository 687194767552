import React, { useState } from 'react';
import { Link } from "react-router-dom";

const Nav = () => {
    const [activeType, setActiveType] = useState('');

    const handleLinkClick = (type) => {
        setActiveType(type);
    };
    console.log(activeType, "typeeee")

    return (
        <div className="main-menu text-center">
            <nav  >
                <ul className="main-menu__list">
                    <li>
                        <Link to={process.env.PUBLIC_URL + `/`}
                            className={activeType === 'home' ? 'active-link' : 'active'}
                            onClick={() => handleLinkClick('home')}>Home</Link>
                    </li>
                    <li>
                        <Link
                            to={process.env.PUBLIC_URL + `/about`}
                            className={activeType === 'about' ? 'active-link' : 'active'}
                            onClick={() => handleLinkClick('about')}
                        >About Us</Link>
                    </li>
                    <li>
                        <Link to={process.env.PUBLIC_URL + `/activities`}
                            className={activeType === 'activities' ? 'active-link' : 'active'}
                            onClick={() => handleLinkClick('activities')}>Activities</Link>
                    </li>
                    <li>
                        <Link to={process.env.PUBLIC_URL + `/projects`}
                            className={activeType === 'projects' ? 'active-link' : ' '}
                            onClick={() => handleLinkClick('projects')}>Projects</Link>
                    </li>
                    <li><Link to={process.env.PUBLIC_URL + `/gallery`}
                        className={activeType === 'gallery' ? 'active-link' : ' '}
                        onClick={() => handleLinkClick('gallery')}>
                        Gallery</Link></li>

                    <li> <Link to={process.env.PUBLIC_URL + `/contact`}
                        className={activeType === 'contact' ? 'active-link' : ' '}
                        onClick={() => handleLinkClick('contact')}>Contact Us</Link></li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav;

