import React from 'react'
import VolunteerForm from '../components/Volunteer/VolunteerForm'
import HeaderOne from '../common/header/HeaderOne'
import Breadcrumb from '../common/breadcrumb/Breadcrumb'
import FooterOne from '../common/footer/FooterOne'


function Volunteer() {
    return (
        <>
            <HeaderOne />
            <Breadcrumb
                heading="Become Volunteer"
                currentPage="Volunteer"
            />
            <VolunteerForm />
            <FooterOne />

        </>
    )
}

export default Volunteer