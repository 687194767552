import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import WOW from 'wowjs';
import { FaAngleUp } from "react-icons/fa";

//Pages
import Login from "./components/Authentication/Login/Login";
import Register from "./components/Authentication/Register/Register";
import HomeDefault from "./pages/HomeDefault";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import Team from "./pages/Team";
import Projects from "./pages/Projects";
import Activities from "./pages/Activities";
import Gallery from "./pages/Gallery";
import Donation from "./pages/Donation";
import DonateNow from "./pages/DonateNow";
import Events from "./pages/Events";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Volunteer from './pages/Volunteer';
import Contact from "./pages/Contact";
import TermsAndConditions from "./pages/TermsAndConditions";
import Cancellation from './pages/Cancellation'
import Privacy from './pages/Privacy';
import Error from "./pages/Error";
import ScrollToTopRoute from './components/scroll-to-top-route/ScrollToTopRoute';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/effect-fade';
import { Provider } from 'react-redux';
import { store } from './redux/store';
function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, []
  );
  return (
    <Provider store={store}>
      <Router> <ToastContainer />
        <ScrollToTopRoute />
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/Login`} exact element={<Login />} />
          <Route path={`${process.env.PUBLIC_URL}/Register`} exact element={<Register />} />
          <Route path={`${process.env.PUBLIC_URL}/`} exact element={<HomeDefault />} />
          <Route path={`${process.env.PUBLIC_URL}/home-two`} exact element={<HomeTwo />} />
          <Route path={`${process.env.PUBLIC_URL}/home-three`} exact element={<HomeThree />} />
          <Route path={`${process.env.PUBLIC_URL}/about`} exact element={<About />} />
          <Route path={`${process.env.PUBLIC_URL}/team`} exact element={<Team />} />
          <Route path={`${process.env.PUBLIC_URL}/Volunteer`} exact element={<Volunteer />} />
          <Route path={`${process.env.PUBLIC_URL}/projects`} exact element={<Projects />} />
          <Route path={`${process.env.PUBLIC_URL}/activities`} exact element={<Activities />} />
          <Route path={`${process.env.PUBLIC_URL}/gallery`} exact element={<Gallery />} />
          <Route path={`${process.env.PUBLIC_URL}/donation`} exact element={<Donation />} />
          <Route path={`${process.env.PUBLIC_URL}/donate-now`} exact element={<DonateNow />} />
          <Route path={`${process.env.PUBLIC_URL}/Events`} exact element={<Events />} /> 
          <Route path={`${process.env.PUBLIC_URL}/contact`} exact element={<Contact />} />
          <Route path={`${process.env.PUBLIC_URL}/terms`} exact element={<TermsAndConditions />} />
          <Route path={`${process.env.PUBLIC_URL}/privacy`} exact element={<Privacy />} />
          <Route path={`${process.env.PUBLIC_URL}/Cancellation`} exact element={<Cancellation />} />
          <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<Error />} />
        </Routes>
        <ScrollToTop className="scrollUp" smooth top="1500" component={<FaAngleUp />} />
      </Router>
    </Provider>
  );
}

export default App;