import React from 'react';

export default class Vision extends React.Component {
  
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<>

				<section className="about-one Vision-one about-three">
					<div className="container">
						<div className="row"> 
								
						<div className="section-title text-center"> 
							<h2 className="section-title__title">Vision & Mission</h2>
						</div>
								<div className="col-xl-7">
								<div className='about-one__right'>
								<ul className="list-unstyled Vision__points">
											<li>
												<div className='Vision-img'>
												<img src={publicUrl + "assets/images/resources/vision1.png"} alt="" />
												</div>
												<div className="text">
													<h4>Vision</h4>
													<p>To envision a world where individuals affected by muscular dystrophy live full, empowered lives, free from the constraints of this condition, and where groundbreaking research, innovative technology, and compassionate care converge to transform their future.</p>
												</div>
											</li>
											<li>
											<span className='Vision-img'>
												<img src={publicUrl + "assets/images/resources/vision2.png"} alt="" />
												</span>
												<div className="text">
													<h4>Mission</h4>
													 <ul>
														<li>
															<p> Our mission is to be a pioneering force in the fight against muscular dystrophy by harnessing the power of technology, advocacy, research,and community support.</p>
														</li>
														<li>
															<p> We are dedicated to advancing the integration of cutting-edge technologies into the diagnosis, treatment, and support systems for individuals and families impacted by this condition. </p>
														</li>
														<li> <p> Through research funding, technological innovation, and compassionate care, we aim to enhance the quality of life for those affected, raise awareness, and contribute to the ongoing quest for effective treatments.</p> </li>
														<li> <p> Together, we work towards a future where individuals with muscular dystrophy can lead fulfilling lives, thanks in part to the transformative potential of technology. </p> </li> 
													 </ul>
												</div>
											</li>
										</ul>
										</div>
								</div>
								<div className="col-xl-5"> 
									<div className='about-one__right'>
									<div className="about-one__img-box">
										<div className="about-one__img">
											<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-8.jpg"} alt="" />
										</div>
										<div className="about-one__small-img wow zoomIn animated animated" data-wow-delay="500ms"
											data-wow-duration="2500ms">
											<img src={publicUrl + "assets/images/resources/footer-widget-one-gallery-7.jpg"} alt="" />
										</div>
										</div>
									</div>
								</div>
						</div>
					</div>
				</section>

			</>
		)
	}
}