import React from 'react';
import { useNavigate } from 'react-router-dom'


const BecomeVolunteer = () => {
    const navigate = useNavigate();
    const handleVolunteerClick = () => {
        navigate('/Volunteer')
    };
    const handleDonateClick = () => {
        navigate('/donate-now')
    };
    return (
        <>

            <div className="Volunteer_form">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <h2> Let Your Love Be The Light That Illuminates Your World.</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="callout-actions">
                                <div onClick={handleVolunteerClick} className="button">Become Volunteer</div>

                                <span className="callout-seperator">
                                    <span>Or</span>
                                </span>

                                <div onClick={handleDonateClick} className="button">Donate For cause</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default BecomeVolunteer;