import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import AboutOne from "../components/about/AboutOne";
import ProjectOne from "../components/project/ProjectOne";

import TeamOne from "../components/team/TeamOne";
import BlogSectionThree from "../components/blog/BlogSectionThree";
import FooterOne from "../common/footer/FooterOne";

const HomeThree = () => {
    return (
        <>
            <HeaderOne />
            <AboutOne />
            <ProjectOne /> 
            <TeamOne />
            <BlogSectionThree />
            <FooterOne />
        </>
    )
}

export default HomeThree;