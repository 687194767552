import React from 'react'; 

export default class Services extends React.Component {
     
    render() {
        let publicUrl = process.env.PUBLIC_URL + '/'
        

        return (
            <>

                <section className="about-one Vision-one about-three">
                    <div className="container">
                        
                                
                        <div className="section-title text-center"> 
                            <h2 className="section-title__title">Our Services</h2>
                        </div>

                        <div className='about-one__right'>
                        <div className="row"> 
                        <div className='col-lg-3 col-md-6 scr-coll'>
                            <div className='services-box'>
                                <div className='services-img' >
                                <img src={publicUrl + "assets/images/resources/weel-chair.PNG  "} alt="" />
                                </div>
                               
                                <div className='services-text'>
                                    <h2>Wheelchairs</h2>
                                    <p>Provide electric
                                        wheelchairs to help the
                                        patients locomote.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 scr-coll'>
                        <div className='services-box'>
                        <div className='services-img' >
                                <img src={publicUrl + "assets/images/resources/Physio-therapy.PNG "} alt="" />
                                </div>
                                <div className='services-text'>
                                    <h2>Physio-therapy</h2>
                                    <p>Create awareness and
                                            organize physiotherapy
                                            camps.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 scr-coll'>
                             <div className='services-box'>
                                <div className='services-img' >
                                    <img src={publicUrl + "assets/images/resources/Medical-Diagnosis.PNG  "} alt="" />
                                </div>
                                <div className='services-text'>
                                    <h2>Medical Diagnosis</h2>
                                    <p>Organize genetic tests to
                                        help detect the disease at
                                        an affordable price.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 scr-coll'>
                             <div className='services-box'>
                                <div className='services-img' >
                                    <img src={publicUrl + "assets/images/resources/Child-Welfare.PNG  "} alt="" />
                                </div>
                                <div className='services-text'>
                                <h2>Child Welfare</h2>
                                    <p>Provide basic education
                                    alongside keeping the kids
                                    engaged with extra curicular
                                    classes like music and
                                    meditation classes. 
                                    </p>
                            </div>
                        </div>
                       </div>
                       
                        </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}